.container {
    max-width: 1360px;
    min-width: 1348px;
    margin: 0 auto;
}
.pageContent {
    padding: 0px;
    background-color: $body-bg;
}
.mainLanding-page {
    margin: 0px auto;
    background-color: $body-bg;
    // background-color: #ececec;
}
.graphSection {
    display: flex;
    flex-direction: column;
    .graphContainer {
        padding: 0px;
    }
    .graphContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        padding: 20px;
        font-family: HelveticaCondensed;
        /* border-radius: 5px; */
        min-height: 200px;
        background-image: linear-gradient(#133667, #27497e);
    }
    .paddingRight{
        padding-right:60px;
    }
}
.sectionTitle small {
    font-size: 16px;
    font-weight: normal;
}
.tableHeader-home,
.tableHeader-away {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 5px 10px;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    border-radius: 5px 5px 0 0;
}
.tableHeader-home .tableHeading,
.tableHeader-away .tableHeading {
    color: #fff;
    font-family: HelveticaCondensed;
    font-weight: 600;
    font-size: 20px;
}
.tableHeader-home .tableHeading > span,
.tableHeader-away .tableHeading > span {
    font-weight: normal;
    font-size: 14px;
    font-family: HelveticaCondensed;
}
.tableHeader-home .tableHeading-rightSection,
.tableHeader-away .tableHeading-rightSection {
    justify-content: flex-end;
}
.tableHeader-home {
    background-color: $homeTeamColor;
}
.tableHeader-away {
    background-color: $awayTeamColor;
}
ul.tabsList {
    padding: 0px 20px;
    // font-size: -0px;
    margin: 0px 0px 0px 0px;
    text-align: center;
    height: 32px;
    line-height: 31px;
    li {
        display: inline;
        margin: 0;
        margin-right: 10px;
        a {
            text-decoration: none;
            position: relative;
            top: -1px;
            padding: 8px 18px;
            cursor: pointer;
            font-size: 15px;
            border-bottom-color: #b7b7b7;
            color: $dark-blue;
            border-radius: 3px 3px 0 0;
            outline: none;
            background-color: $background-white;
        }
        &.active a {
            font-weight: 600;
            background-color: $tertiaryColor;
            color:$white;
            border: 0px solid #ff6f29;
            border-bottom-color: #3f6df5;
            font-family: inherit;
        }
    }
}
.tabSection {
    width: 100%;
    flex-direction: column;
    display: flex;
    // padding-top: 5px;
    position: relative;
    .tabsContent {
        display: flex;
        flex-direction: column;
        background-color: $secondaryColor;
        padding-top: 20px;
        color: #fff;

        .tabContent {
            width: 100%;
            animation: fadein 0.6s;
        }
    }
    .tabTable-content {
        padding: 0px 20px 20px;
    }
}
.tab {
    cursor: pointer;
    width: 100%;
    background: #fff;
    display: inline-block;
    color: #000;
    font-size: 13px;
}
@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
.panel-title {
    font-size: 15px;
    font-weight: 600;
}
.table-fixture {
    display: flex;
    width: 330px;
    font-weight: 700;
    color: #001a69;
    // justify-content: center;
    text-transform: uppercase;
    transform: scale(1, 1.1);
}
.team-image {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 200px;
    align-items: center;
    .homeTeam-name, .awayTeam-name {
        width: 168px;
    }
    .homeTeam-name {
        padding-right: 5px;
        text-align: right;
    }
    .awayTeam-name {
        padding-left: 5px;
        text-align: left;
    }
    img {
        width: 25px;
    }
}
.status {
    display: inline-block;
    text-align: center;
    padding: 3px;
    margin: 1px;
    border-radius: 3px;
    color: #fff;
    width: 24px;
    min-width: 24px;
    max-width: 24px;
    line-height: 20px;
    &.win {
        background-color: $win;
    }

    &.loss {
        background-color: $loss;
    }

    &.draw {
        background-color: $draw;
    }

    &.High {
        background-color: $high;
    }

    &.Low {
        background-color: $low;
    }

    &.BTTSno {
        background-color: $BTTSno;
    }

    &.BTTSyes {
        background-color: $BTTSyes;
    }
}
.win-predictor {
    display: inline-flex;
    width: 200px;
    padding: 2px 0;
    justify-content: center;
    .percentage {
        // display: inline-block;
        display:flex;
        justify-content:center;
        padding: 3px 6px;
        // text-align: center;
        color: #fff;
        font-size: 12px;
        &.win {
            background-color: $win;
            border-radius: 15px 0 0 15px;
        }

        &.draw {
            background-color: $draw;
        }

        &.loss {
            background-color: $loss;
            border-radius: 0px 15px 15px 0px;
        }
    }
}
.form-grid {
    display: flex;
    margin: 2px 0;
    width: 100%;
}
.form-col {
    margin: 1px 2px;
    width: 100%;
}
.form-group {
    &.form-inline {
        display: flex;
        flex-direction: row;
        padding-left: 10px;
    }
    .form-label {
        display: flex;
        font-size: 14px;
        align-items: center;
        margin-right: 10px;
        color: #001a69;
        margin-bottom: 2px;
        font-weight: 600;
    }
    .form-control {
        input,
        select {
            height: 30px;
            border: solid 1px #ccc;
            border-radius: 3px;
            font-size: 13px;
        }

        input {
            text-indent: 8px;
        }

        select {
            color: #252525;
            padding: 2px 6px;
            min-width: 58px;
        }
    }
}
.dropdown-position {
    position: absolute;
    right: 20px;
    // top: 6px;
    color: #fff;
}
.white {
    color: #fff !important;
}
.tableTwo-column {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px 20px;
    &.noPadding {
        padding: 0;
    }
    .tableHome,
    .tableAway {
        display: flex;
        flex-direction: row;
        width: 50%;
    }
    .tableHome {
        justify-content: flex-start;
    }
    .tableAway {
        justify-content: flex-end;
    }
    .tableFull-width {
        width: 99%;
    }
}
.leagueDropdown {
    width: 100%;
}
.textIcon {
    display: flex;
    max-width: 102px;
    margin: 0 auto;
    justify-content: space-between;
    span {
        padding-right: 5px;
        justify-content: center;
        align-items: center;
        display: flex; 
        text-align: center;  
        
    }
}
// Seasonal stats common properties
.seasonalStats {
    background-color: $secondaryColor;

    .seasonalStats-container {
        padding: 0px;
    }
    .seasonalStats-content {
        padding: 0 20px 20px;
    }
}
.seasonalStats-tablesList {
    display: flex;
    background-color: #bed2ec;
    padding: 10px 0;
    justify-content: center;
    border-bottom: solid 2px #fff;
    .listItems {
        display: flex;
        flex-direction: row;
        padding: 0px 90px;
        line-height: 25px;
        text-align: center;
        ul {
            overflow: hidden;
            li {
                display: inline-block;
                font-size: 16px;
                font-weight: 600;
                a {
                    display: inline;
                    text-align: center;
                    padding: 6px;
                    text-decoration: none;
                    color: $black;
                }
                &:hover a {
                    color: #272727;
                }
                &.active a {
                    color: $tertiaryColor;
                }
                &:not(:last-child):after {
                    content: "|";
                    color: #000;
                }
            }
        }
    }
}
.sectionHeading {
    background-color: #4b6e9f;
    padding: 10px 20px;
    height: 30px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .sectionTitle {
        font-family: HelveticaCondensed;
        font-size: 22px;
        font-weight: 600;
        text-transform: uppercase;
        span{
            text-transform: none;
        }
    }
    .sectionFilter {
        display: flex;
        align-items: center;
    }
    .form-label {
        color: #fff;
    }
}
.extraLarge {
    font-size: 40px;
}
.large {
    font-size: 25px;
    font-weight: 600;
}
.medium {
    font-size: 20px;
}
.small {
    font-size: 15px;
}
.extraSmall {
    font-size: 12px;
}
.formStatus {
    width: 200px;
}
.formGroup-container {
    display: flex;
}
.homeBg {
    background-color: $homeTeamColor;
}
.awayBg {
    background-color: $awayTeamColor;
}
.neutral{
    background-color: #FF6F29;
}
.teamSelected {
    color: #fff;
    font-weight: 600;
}
// head-to-head-hkjc-mode
.headTo-headHKJC {
    padding-top: 20px;
    background-color: #ddd;
}
.headTo-headHKJC-statistics {
    padding-top: 20px;
    background-color: $secondaryColor;
}
.recentForm-info {
    text-align: center;
    margin: 5px;
    .selectedData {
        border: solid 1px #ddd;
        width: 60%;
        padding: 5px;
        border-radius: 5px;
        line-height: 30px;
        margin: 5px auto;
        .fixture {
            font-weight: 600;
            .score {
                background-color: #001a69;
                color: #fff;
                text-align: center;
                padding: 3px 10px;
                border-radius: 4px;
                letter-spacing: 2px;
            }
        }
    }
}
.hiloGraphtables {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .heading {
        margin: 20px 0;
    }
}
.hiLoGraph {
    text-align: center;
}
.hiLoGraph-header {
    display: flex;
    justify-content: space-between;
    text-align: center;
    margin-bottom: 10px;
    min-height: 20px;
    .line-title {
        width: 100px;
        justify-content: center;
        font-family: "Montserrat", sans-serif;
        font-size: 15px;
    }
    .mainTitle {
        font-size: 20px;
        font-weight: 600;
        text-transform: none;
        small{
            font-weight: normal;
        }
    }
    .legend-info {
        display: flex;
        align-items: center;
        font-size: 13px;
        border-radius: 15px;
        font-family: "Montserrat", sans-serif;
        /* border: solid 1px #5901c4; */
        padding: 0 5px;
        .homeLegend,
        .awayLegend {
            width: 20px;
            height: 20px;
            margin: 0 5px;
            border-radius: 4px;
            text-align: center;
        }
        .homeLegend {
            background-color: #74aa8c;
        }
        .awayLegend {
            background-color: #ebbf32;
        }
    }
}
.barGraph,
.hiLoGraph-body {
    display: flex;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
}
.lineContent {
    // border: solid 1px #405f85;
    border-radius: 4px;
    padding: 0 20px;
    height: 100%;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    line-height: 43px;
    margin: 0 20px;
}
.barsContent {
    text-align: center;
}
.hiLoBar {
    width: 460px;
    height: 24px;
    display: flex;
    align-items: stretch;
    border-radius: 12px;
    overflow: hidden;
    margin: 20px 0;
    align-items: center;
    line-height: 25px;
    .homeBar {
        background-color: #ebbf32;
        flex: var(--blue);
        color: #000;
    }
    .awayBar {
        background-color: #74aa8c;
        flex: var(--red);
    }
}
.bar > * {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sectionMain-title {
    display: flex;
    justify-content: space-between;
    width: 49.5%;
    align-items: center;
}
.internalGraph {
    display: flex;
    justify-content: space-between;
    color: #fff;
}
.graphGrid {
    margin-top: 0px;
}
.pastSeasons {
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #000;
    font-size: 20px;
    font-weight: 600;
    padding: 25px 20px;
    line-height: 30px;
    .pastSeasons-block {
        border: solid 1px #4b6e9f;
        border-radius: 4px;
        width: 25%;
        padding: 20px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .homeWins {
        background-color: #a9edf5;
    }
    .draws {
        background-color: #f2f2f2;
    }
    .awayWins {
        background-color: #f3c7c7;
    }
}
.pastSeasons-grid {
    background-color: #fff;
    margin: 0;
    padding: 10px 0;
    border-bottom: solid 1px #eee;
    .small {
        padding: 5px 10px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
    }
}
.dualHilo-graph {
    display: flex;
    margin: 0 143px;
    .middleLine {
        text-align: center;
        margin-bottom: 23px;
        font-size: 15px;
        font-family: "Montserrat", sans-serif;
        min-height: 20px;
    }
}
sup {
    top: 0;
}
.teamSelection {
    display: flex;
    justify-content: center;
    .teamSelection-heading {
        display: flex;
        width: 350px;
        background-color: #fff;
        border-radius: 4px 4px 0 0;
        margin-top: 10px;
        text-align: center;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 15px;
        border-bottom: 1px solid #536697;
        height: 40px;
        font-size: 16px;
        font-weight: bold;
        color: #17396b;
    }
}
.th-separator {
    border-right: solid 1px #314977;
}
.th-spaceSeparator {
    border-right: solid 8px #292e3b;
}
.th-separator2 {
    border-right: solid 1px #bbb;
}
.th-spaceSeparator2 {
    border-right: solid 8px #ddd;
}
hr {
    border: solid 1px #49679e;
}
.fullPage {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.lft_sidebar {
    display: flex;
    flex-direction: column;
    align-items: justify;
    width: 85%;
    line-height: 30px;
    background-color: #f5f7ff;
}
.rft_sidebar {
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 15%;
}
.poweredBy {
    display: flex;
    background-color: #efefef;
    align-items: center;
    .poweredBy-logo img {
        max-width: 200px;
        margin-right: 10px;
    }
    .poweredBy-content {
        font-size: 9px;
        line-height: 1.5em;
        padding: 20px;
    }
}
/* loading css */
.noData {
    display: flex;
    line-height: 50px;
    flex-direction: column;
    align-items: center;
    font-weight: 600;
}
.loader {
    margin: 10px auto;
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid blue;
    border-bottom: 5px solid blue;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
.gameSearch {
    display: flex;
    align-items: center;
    flex-direction: row;
    background-color: #fff;
    margin: 0 auto;
    padding: 0px 15px;

    .form-grid {
        width: 45%;
    }
}
.teamSelection-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 20px;
}
.fadeout {
    color: #bdbfbe;
}

/* inner tabs  */
.tab{
    cursor: pointer;
    padding:5px 25px;
    margin:0px 0px 10px 0px;
    background:#fff;
    display:inline-block;
    color:$secondaryColor;
    border-radius:3px 0px 0px 3px;
    border: solid 1px #001A69;
    font-size: 13px;
  }
  .HKJC-panels{
    background:#ffffff;  
    width:100%;   
    overflow:hidden;
    padding: 5px 0;   
  }
  .HKJC-panel{
    display:none;
    animation: fadein .8s;
  }
  @keyframes fadein {
      from {
          opacity:0;
      }
      to {
          opacity:1;
      }
  }
  .HKJC-panel-title{
    font-size:15px;
    font-weight:600
  }
  .HKJC-radio{
    display:none;
  }
  .HKJC-label1 {    
    margin-left: 180px;
  }
  .HKJC-label2 {
    position: relative;
    left:-4px;
    border-radius: 0px;
    border-left: none;
    border-right: none;
  }
  .HKJC-label3 {
    position: relative;
    left:-8px;
    border-radius: 0px;
    border-left: none;
    border-right: none;
  }
  .HKJC-label4 {
    position: relative;
    left:-12px;
    border-radius: 0px 3px 3px 0px;
    border-left: none;
  }
  #one:checked ~ .HKJC-panels #one-panel,
  #two:checked ~ .HKJC-panels #two-panel,
  #three:checked ~ .HKJC-panels #three-panel,
  #four:checked ~ .HKJC-panels #four-panel,
  #five:checked ~ .HKJC-panels #five-panel,
  #six:checked ~ .HKJC-panels #six-panel,
  #seven:checked ~ .HKJC-panels #seven-panel,
  #eight:checked ~ .HKJC-panels #eight-panel{
    display:block;
  }
  #one:checked ~ .HKJC-tabs #one-tab,
  #two:checked ~ .HKJC-tabs #two-tab,
  #three:checked ~ .HKJC-tabs #three-tab,
  #four:checked ~ .HKJC-tabs #four-tab,
  #five:checked ~ .HKJC-tabs #five-tab,
  #six:checked ~ .HKJC-tabs #six-tab,
  #seven:checked ~ .HKJC-tabs #seven-tab,
  #eight:checked ~ .HKJC-tabs #eight-tab  {
    background:$secondaryColor;
    color:#fff;
    
  }