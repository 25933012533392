.dualGrah {
  display: flex;
  flex-direction: column;
}
.dualHilo-table {
  margin: 0 100px;
}
.statisticsTables {
  thead th {
    padding: 8px 7px;
  }
  th:nth-child(1) {
    text-align: left;
  }
  tbody
  td {
    height: 35px;
  }
  padding: 20px 100px;
  td:nth-child(1) {
    text-align: left;
  }
}
