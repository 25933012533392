.winPredictor-bestBet {
  display: flex;
  justify-content: space-between;
  margin: 0 20px 15px 20px;
  font-family: "Montserrat", sans-serif;
  .winPredictor {
    display: flex;
    background-color: #224bb2;
    background-image: url(#{$img-path}/winPredictor-bg.jpg);
    background-size: cover;
    padding: 10px;
    width: 48%;
    border-radius: 12px;
    min-height: 150px;
    box-shadow: $boxshadow;
    .winPredictor-iconGroup {
      text-align: center;
      justify-content: flex-start;
      display: flex;
      flex-direction: column;
      width: 218px;
      .winPredictor-icon,
      img {
        height: 80px;
      }
      .win {
        display: flex;
        font-size: 55px;
        color: #fff;
        font-weight: 600;
        height: 45px;
        justify-content: center;
        align-items: center;
        font-family: HelveticaCondensed;
        text-transform: uppercase;
      }
      .win-chinese {
        display: flex;
        font-size: 35px;
        color: #fff;
        font-weight: 600;
        height: 45px;
        justify-content: center;
        align-items: center;
        font-family: HelveticaCondensed;
        text-transform: uppercase;
      }
      .predictor {
        font-size: 18px;
        color: #fff;
        font-weight: 600;
        text-align: center;
        font-family: HelveticaCondensed;
        text-transform: uppercase;
      }
    }
    .winPredictor-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .bestBet {
    display: flex;
    background-color: #fff;
    padding: 10px;
    width: 48%;
    border-radius: 12px;
    justify-content: flex-start;
    height: 150px;
    box-shadow: $boxshadow;
    .bestBet-strip {
      position: relative;
      right: 43px;
    }
    .winPredictor-iconGroup {
      align-items: center;
      justify-content: flex-start;
      display: flex;
      flex-direction: column;
      min-width: 140px;
      max-width: 140px;
      margin-right: 20px;
      .winPredictor-icon,
      img {
        height: 80px;
      }
      .win {
        display: flex;
        font-size: 55px;
        color: #001a69;
        font-weight: 600;
        height: 45px;
        justify-content: center;
        align-items: center;
        font-family: HelveticaCondensed;
        text-transform: uppercase;
      }
      .best-chinese {
        display: flex;
        font-size: 35px;
        color: #001a69;
        font-weight: 600;
        height: 45px;
        justify-content: center;
        align-items: center;
        font-family: HelveticaCondensed;
        text-transform: uppercase;
      }
      .predictor {
        font-size: 18px;
        color: #001a69;
        font-weight: 600;
        text-align: center;
        font-family: HelveticaCondensed;
        text-transform: uppercase;
      }
    }
    .bestBet-graph {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 150px;
      min-width: 150px;
      height: 150px;
      justify-content: flex-start;
      position: relative;
      margin-left: 10px;
      top: -37px;
      img {
        height: 150px;
      }
      .bestBet-verticaltext {
        font-weight: 600;
        position: relative;
        text-align: center;
        left: -72px;
        height: 30px;
        top: 90px;
        font-size: 16px;
        line-height: 15px;
        color: #652CB7;
        transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    }
    .highChart {
        position: relative;
        top: -30px;
    }
    .bestBet-horizontaltext {
        font-size: 16px;
        color: #652CB7;
        font-weight: 600;
        text-align: center;
        top: -14px;
        position: relative;
    }
    }
    .bestBet-content {
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: space-around;
      .bestBet-fixture {
        font-size: 16px;
        align-items: flex-start;
        font-weight: bold;
        color: #224bb2;
      }
      .rtb {
        display: flex;
        padding: 10px;
        line-height: 25px;
        font-size: 15px;
        color: #555;
      }
    }
  }
  .ribbon {
    height: 30px;
    position: relative;
    font-size: 12px;
    color: white;
  }
  .ribbon3 {
    width: 60px;
    height: 25px;
    line-height: 27px;
    padding-left: 10px;
    position: absolute;
    left: 20px;
    top: 0px;
    background: #e0213e;
    &:before,
    &:after {
      content: "";
      position: absolute;
    }
    &:before {
      height: 0;
      width: 0;
      top: -7px;
      left: 0px;
    }
    &:after {
      height: 0;
      width: 0;
      right: -3.5px;
      border-top: 13px solid transparent;
      border-bottom: 12px solid transparent;
      border-left: 4px solid #e0213e;
    }
  }
}
.textRed {
  color: rgb(128, 125, 125);
}
.textBlue {
  color: #224bb2;
}
.goalTimes {
  width: 800px;
}
.goalTimes-graph {
  width: 600px;
  margin-left: 100px;
}
.firstTo-score-hilo {
  width: 500px !important;
}
.teamTo-score {
  margin: 0 auto;
}
.totalGoals-analysis {
  margin-top: 105px;
}
.teamToscore-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  .teamToscore-circles {
      display: flex;
      flex-direction: row;
      text-align: center;
  }
  .mainTitle {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 20px;
      text-transform: none;
  }
  .teamTo-score {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      padding: 5px;
      display: inline-block;
      margin: 5px;
      justify-content: center;
      align-items: center;
      display: flex;
      font-weight: 600;
      .teamToscore-common {
          display: flex;
          flex-direction: column;
      }
      &.neither,
      &.one {
          background-color: #fff;
          color: #e0129c;
      }
      &.both {
          background-color: #e0129c;
      }
  }
}
.moreExpand {
  margin: 10px 50px;
}
.moreIcon {
  margin: 5px;
}