.standingTable-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 10px 20px;
  .standingTable-group {
      display: flex;
      flex-direction: row;
      width: 644px;
      margin: 10px 10px 0;
  }
}
.tableDefault.standingsTable {
  tbody td:first-child {
      text-align: left;
  }
  thead th {
      background-color: #052754;
      color: #fff;

      &:first-child {
          text-align: left;
      }
  }
  .groupHeading {
      font-size: 20px;
      font-weight: 600;
  }
}
