.keyIndicators {
  display: flex;
  flex-direction: row;
  align-items: center;
  // padding: 5px 40px 5px 60px;
  justify-content: space-between;
  .text {
    font-weight: 600;
    margin: 5px 60px;
  }
  .rank {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: solid 1px #555;
    display: flex;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    background-color: #ffcc00;
    color: #000;
    margin: 5px 90px;
  }
}
.home-bg {
  background-color: #29d9d7;
}
.away-bg {
  background-color: #f17f80;
}
.white-bg {
  background-color: #fff;
}
.keyIndicators {
  &.home-bg,
  .away-bg {
    color: #000;
    // padding: 5px 40px;
    justify-content: space-between;
  }
  &.home-bg {
    background-color: #29d9d7;
    border-radius: 0 5px 5px 0;
  }
  &.away-bg {
    background-color: #f17f80;
    border-radius: 5px 0 0 5px;
  }
}
.w-500{
  width:500px;
}
.pr-10{
  padding-right: 10px;
}
.pl-15{
  padding-left: 15px;
}